<template>
  <div class="profilePassword">
    <div class="profilePassword__title">
      {{ $t('profilePassword.title') }}
    </div>

    <p class="profilePassword__body">
      {{ $t('profilePassword.body') }}
    </p>

    <Form
      v-bind:validation-schema="validationSchema"
      v-slot="{ handleSubmit, isSubmitting }"
    >
      <base-form v-on:submit="handleSubmit($event, onSubmit)">
        <base-form-element class="profilePassword__passwordField">
          <base-form-label for-id="password">
            {{ $t('profilePassword.passwordFieldLabel') }}
          </base-form-label>

          <base-form-input
            type="password"
            id="password"
          />

          <base-form-element-note>
            {{ $t('form.passwordFieldNote') }}
          </base-form-element-note>

          <base-form-element-error name="password" />
        </base-form-element>

        <base-form-element class="profilePassword__passwordField">
          <base-form-label for-id="password">
            {{ $t('profilePassword.passwordConfirmationFieldLabel') }}
          </base-form-label>

          <base-form-input
            type="password"
            id="passwordConfirmation"
          />

          <base-form-element-error name="passwordConfirmation" />
        </base-form-element>

        <base-form-element>
          <base-button
            modifiers="primary"
            v-bind:show-spinner="isSubmitting"
            v-bind:disabled="isSubmitting"
          >
            {{ $t('profilePassword.saveButtonLabel') }}
          </base-button>
        </base-form-element>
      </base-form>
    </Form>

    <profile-saved-notification v-if="showSavedNotification" />
  </div>
</template>

<script>
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BaseForm from '@/components/forms/BaseForm';
import BaseFormElement from '@/components/forms/BaseFormElement';
import BaseFormElementNote from '@/components/forms/BaseFormElementNote';
import BaseFormElementError from '@/components/forms/BaseFormElementError';
import BaseFormLabel from '@/components/forms/BaseFormLabel';
import BaseFormInput from '@/components/forms/BaseFormInput';
import BaseButton from '@/components/BaseButton';
import ProfileSavedNotification from '@/components/profile/ProfileSavedNotification';
import { i18n } from '@/plugins/i18n';

export default {
  components: {
    Form,
    BaseForm,
    BaseFormElement,
    BaseFormElementNote,
    BaseFormElementError,
    BaseFormLabel,
    BaseFormInput,
    BaseButton,
    ProfileSavedNotification,
  },

  data() {
    return {
      showSavedNotification: false,
      validationSchema: yup.object({
        password: yup
          .string()
          .min(6, () => i18n.global.t('form.errorPasswordLength'))
          .required(() => i18n.global.t('form.errorRequired')),
        passwordConfirmation: yup
          .string()
          .oneOf([yup.ref('password'), null], () => i18n.global.t('form.errorPasswordMatch'))
          .required(() => i18n.global.t('form.errorRequired')),
      }),
    };
  },

  methods: {
    async onSubmit(values, { resetForm }) {
      try {
        await this.$store.dispatch('member/changePassword', values.password);

        this.showSavedNotification = true;

        setTimeout(() => {
          this.showSavedNotification = false;
        }, 2000);

        resetForm();
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profilePassword__title {
  @include heading-5;
  margin: 0 0 rem(24px) 0;
}

.profilePassword__body {
  @include small;
  margin: 0 0 rem(24px) 0;
  max-width: 44ch;
}

.profilePassword__passwordField {
  max-width: 250px;
}
</style>
